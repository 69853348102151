import { forwardRef } from 'react';
import { Description, Icon, Img, InputWrapper } from '../input/input';
import { InputFieldNative } from 'src/styling/globalStyling';

interface InputNativeProps {
  label?: string;
  description?: string;
  wrapperstyle?: any;
  inputstyle?: any;
  descriptionPositionUp?: 'top';
  iconClickHandler?: () => void;
  horizontal?: boolean;
  hasLabelSpace?: boolean;
  icon?: string;
  isvalid?: string;
  className?: string;
}

const InputNative = forwardRef((props: InputNativeProps, ref) => {
  const {
    label,
    description,
    wrapperstyle,
    inputstyle,
    descriptionPositionUp,
    iconClickHandler,
    horizontal,
    className,
    hasLabelSpace,
    ...propsToPass
  } = props;

  const icon =
    props.icon && props.icon.indexOf('data:image') !== -1 ? (
      <Img src={props.icon} alt={'icon'} />
    ) : (
      <Icon label={props.label}>
        <i className={props.icon + ' link'} onClick={iconClickHandler} />
      </Icon>
    );

  return (
    <InputWrapper
      className={className}
      horizontal={horizontal}
      style={wrapperstyle}
    >
      {label ? (
        <label>
          {label} {descriptionPositionUp && <span>{description}</span>}
        </label>
      ) : hasLabelSpace ? (
        <label>&nbsp;</label>
      ) : null}
      <InputFieldNative
        autoComplete='off'
        ref={ref}
        {...propsToPass}
        style={{
          paddingRight: props.icon ? '50px' : '16px',
          ...inputstyle,
        }}
      />
      {props.icon && icon}
      {description && !descriptionPositionUp && (
        <Description>{description}</Description>
      )}
    </InputWrapper>
  );
});

export default InputNative;
