import InputNative from 'src/components/InputNative/InputNative';
import { useLang } from 'src/components/useLang';

const inputStyle = {
  border: '1px solid gray',
};
const wrapperstyle = { marginTop: '14px', width: '100%' };

export const UserForm = ({ form }) => {
  const lang = useLang();
  const {
    formState: { errors },
    register,
  } = form;

  return (
    <>
      <InputNative
        label={lang.label_full_name}
        {...register('name', { required: true })}
        inputstyle={inputStyle}
        wrapperstyle={wrapperstyle}
        isvalid={errors['name'] ? 'error' : undefined}
      />
      <InputNative
        label={lang.label_email}
        {...register('email', {
          required: true,
          pattern:
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
        })}
        inputstyle={inputStyle}
        wrapperstyle={wrapperstyle}
        isvalid={errors['email'] ? 'error' : undefined}
      />
    </>
  );
};
