import React from "react";
import Tabs from "../../components/tabs/tabs";
import { Flex, Label } from "../../styling/globalStyling";
import styled, { withTheme } from "styled-components";
import Input from "../../components/input/input";
import { connect } from "react-redux";
import {
  getTopActive,
  getTopPopular,
  setFangouts,
  setShows,
} from "./fangoutsActions";
import FangoutComponent from "../../components/fangoutComponent/fangoutComponent";
import {
  expiredFangouts,
  filterShows,
  handleLoadAddressOptions,
} from "../../utils";
import { Tab, Tabs as SWTabs } from "../../components/sweapable-tabs/index.ts";
import emptyAvatar from "../../assets/images/empty-avatar.jpg";
import {
  clearSearchResults,
  searchFangouts,
  searchShows,
  searchUsers,
  setSearchTerm,
  setTab,
} from "../requestFangout/search/searchReducer";
import { colourStyles, debounce } from "../Login/utils";
import Popup from "../../components/popup/popup";
import Portal from "../../components/portal/portal";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {
  Content,
  InlineInput,
  InlineInputsContainer,
} from "../createFangout/createFangout";
import moment from "moment";
import { dateFormat } from "../../constants";
import PopularProfile from "./PopularProfile";
import ReactGA from "react-ga4";
import ShowItem from "../showItem/showItem";

class Fangouts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      showClearSearch: false,
      expanded: {},
      fangouts: null,
      users: null,
      openFilterModal: false,
      filterProps: {
        addressObject: { label: "", value: null },
        addressOptions: [],
        // fromDate: moment().format(dateFormat),
        fromDate: "",
        // toDate: moment().add(3, 'd').format(dateFormat),
        toDate: "",
        name: "",
      },
      showSearch: "",
      imgSrc: "",
      canvasSource: [],
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: this.props.location.pathname });

    if (this.props.fangouts.length === 0) {
      this.props.setFangouts();
    }
    if (this.props.shows.length === 0) {
      this.props.setShows();
    }
    this.setState({ search: this.props.searchTerm });

    if (!this.props.topActive.length || this.props.topActive[0] === null) {
      this.props.getTopActive(8);
    }

    if (!this.props.topPopular.length || this.props.topPopular[0] === null) {
      this.props.getTopPopular(8);
    }
    // this.props.searchUsers('boza')
  }

  handleSerchChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    this.setState({ search: value });
    this.search(value);
  };

  search = debounce((value) => {
    this.props.setSearchTerm(value);
    if (value) {
      this.props.searchUsers(value);

      this.props.searchFangouts(
        {
          name: value,
          cityName: value,
          profession: value,
        },
        true
      );

      this.props
        .searchShows(
          {
            showTitle: value,
            cityName: value,
            profession: value,
          },
          true
        )
        .then(() => {
          this.props.setTab(2);
        });
    } else {
      this.props.setFangouts();
      this.props.setShows();
      this.props.clearSearchResults();
    }
  }, 500);

  expandFangout = (e, id) => {
    e.stopPropagation();
    this.setState({
      expanded: Object.assign({}, this.state.expanded, {
        [id]: !this.state.expanded[id],
      }),
    });
  };

  goToProfile = (e, celebrity) => {
    e.stopPropagation();
    this.props.history.push({
      pathname: "/public/" + celebrity.id,
      user: celebrity,
    });
  };

  goToFangoutDetails = (f) => {
    this.props.history.push({
      pathname: "/fangout/" + f.id,
      state: { fangout: f },
    });
  };

  openFilters = () => {
    ReactGA.event({
      category: "Modal",
      action: "Click",
      label: "Fangous filter",
    });
    this.setState({ openFilterModal: true });
  };

  goToUserPublicProfile = (user) => {
    this.props.history.push({
      pathname: "/public/" + user.id,
      user: user,
    });
  };

  clearSearch = () => {
    this.setState({ search: "", showClearSearch: false });
    this.props.setFangouts();
    this.props.setShows();
    this.props.setSearchTerm("");
    this.props.clearSearchResults();
  };

  getFilterContent = () => {
    const selectStyles = colourStyles(this.props.theme);
    return (
      <div className={"full-width"}>
        <ExtendedContent>
          <Label>{this.props.lang.label_location}</Label>
          <AsyncSelect
            id={"address"}
            value={this.state.filterProps.addressObject}
            onChange={(location) =>
              this.setState({
                filterProps: {
                  ...this.state.filterProps,
                  addressObject: location,
                },
              })
            }
            loadOptions={handleLoadAddressOptions}
            defaultOptions={this.state.filterProps.addressOptions}
            className={"address"}
            styles={selectStyles}
            cacheOptions
          />
          <InlineInputsContainer className={""}>
            <InlineInput>
              <Input
                label={this.props.lang.label_from}
                type={"date"}
                value={this.state.filterProps.fromDate}
                inputstyle={{
                  minWidth: "-webkit-fill-available",
                  paddingRight: "5px",
                  paddingLeft: "8px",
                  background: this.props.theme.background,
                }}
                onChange={(e) => {
                  this.setState({
                    filterProps: {
                      ...this.state.filterProps,
                      fromDate: e.target.value,
                    },
                  });
                }}
                min={moment().format(dateFormat)}
              />
            </InlineInput>
            <InlineInput>
              <Input
                label={this.props.lang.label_to}
                type={"date"}
                value={this.state.filterProps.toDate}
                inputstyle={{
                  minWidth: "-webkit-fill-available",
                  paddingRight: "5px",
                  paddingLeft: "8px",
                  background: this.props.theme.background,
                }}
                onChange={(e) =>
                  this.setState({
                    filterProps: {
                      ...this.state.filterProps,
                      toDate: e.target.value,
                    },
                  })
                }
              />
            </InlineInput>
          </InlineInputsContainer>
          <Input
            label={this.props.lang.label_celebrity}
            type={"text"}
            value={this.state.filterProps.name}
            inputstyle={{ background: this.props.theme.background }}
            onChange={(e) =>
              this.setState({
                filterProps: {
                  ...this.state.filterProps,
                  name: e.target.value,
                },
              })
            }
          />
        </ExtendedContent>
      </div>
    );
  };

  onFilterSubmit = () => {
    const { name, addressObject, fromDate, toDate, profession } =
      this.state.filterProps;
    if (name) {
      this.props.searchUsers(name);
    }
    this.props
      .searchFangouts({
        name: name || null,
        cityName: addressObject?.value?.address?.city,
        startDate: fromDate ? moment(fromDate).utc().format() : null,
        endDate: toDate ? moment(toDate).utc().format() : null,
        profession: profession || null,
      })
      .then(() => {
        this.props.setTab(0);
      })
      .finally(() => {
        this.setState({ openFilterModal: false, showClearSearch: true });
      });

    this.props
      .searchShows({
        showTitle: name || null,
        cityName: addressObject?.value?.address?.city,
        startDate: fromDate ? moment(fromDate).utc().format() : null,
        endDate: toDate ? moment(toDate).utc().format() : null,
        profession: profession || null,
      })
      .then(() => {
        this.props.setTab(0);
      })
      .finally(() => {
        this.setState({ openFilterModal: false, showClearSearch: true });
      });
  };

  gotosent = () => {
    this.props.history.push("/requests/1"); // sent
  };

  render() {
    const lang = this.props.lang;
    // const fangoutsToShow = this.props.fangouts
    //   .filter((fang) => fang.status === "AVAILABLE")
    //   .filter(expiredFangouts);

    return (
      <HomeContainer id={"homePage"}>
        <Header>
          <MainHeadingLine>
            <i className={"icon-logo"} />
            {process.env.REACT_APP_ENV === "development" && <span>Dev</span>}
            <div className={"toggleSearch"}>
              <i
                className={"fas fa-search"}
                onClick={() => {
                  this.setState({ showSearch: !this.state.showSearch });
                  if (!this.state.showSearch) {
                    // If should be focused
                    setTimeout(
                      () => this.inputRef.current.getInputDOMNode().focus(),
                      100
                    );
                  } else {
                    this.inputRef.current.getInputDOMNode().blur();
                  }
                }}
              />
            </div>
          </MainHeadingLine>
          <div
            className={`search ${
              (this.state.showSearch || this.state.search) && "visible"
            }`}
          >
            <Input
              reference={this.inputRef}
              type={"text"}
              name={"search"}
              placeholder={"Search"}
              wrapperstyle={{
                marginTop: 0,
                flexGrow: 1,
                minWidth: "100px",
              }}
              inputstyle={{ paddingRight: "70px" }}
              value={this.state.search}
              onChange={this.handleSerchChange}
              icon={"icon-filter"}
              iconClickHandler={this.openFilters}
            />
            {(this.state.search || this.state.showClearSearch) && (
              <i
                onClick={this.clearSearch}
                className={"icon-remove reset-search"}
              />
            )}
          </div>
        </Header>
        {this.props.srcUsers.length ||
        this.props.srcFangouts.length ||
        this.props.srcShows.length ? (
          <div style={{ height: "100%" }}>
            <SWTabs
              value={this.props.selectedTab}
              onChange={(t) => this.props.setTab(t.key)}
              tabBarCSS={`
						font-size: 16px;
						font-weight: 500;
						height: 56px;
						margin-top: -16px;
						margin-bottom: 16px;
						`}
              tabItemCSS={`
						line-height: 56px;
						font-size: 24px;
						padding-top: 5px;
						`}
              styleProps={{
                barColor: "transparent",
                selectedHeaderTextColor: this.props.theme.primary,
                headerTextColor: "#fff",
                activeInkBarColor: this.props.theme.primary,
                inkBarColor: "transparent",
                inkBarSize: "1px",
                size: "medium",
                lineHeight: "56px",
              }}
            >
              <Tab label={<i className={"icon-logo_glass"} />} key={0}>
                <div className={"p016 pb300 noscroll"}>
                  {this.props.srcFangouts
                    .filter((fang) => fang.status === "AVAILABLE")
                    .filter(expiredFangouts)
                    .map((f) => (
                      <FangoutComponent
                        f={f}
                        key={f.id}
                        expanded={this.state.expanded}
                        expandFangout={this.expandFangout}
                        goToProfile={this.goToProfile}
                        goToFangoutDetails={this.goToFangoutDetails}
                        withShare
                      />
                    ))}
                </div>
              </Tab>

              <Tab label={<i className={"fas fa-guitar"} />} key={1}>
                <div className={"p016 pb300 noscroll"}>
                  {this.props.srcShows.map((s) => (
                    <ShowItem key={s.id} show={s} />
                  ))}
                </div>
              </Tab>

              <Tab label={<i className={"icon-profile"} />} key={2}>
                <div className={"p016 pb300 noscroll"}>
                  {this.props.srcUsers.map((user) => {
                    return (
                      <User key={user.username}>
                        <div
                          className={"userDetails"}
                          onClick={() => this.goToUserPublicProfile(user)}
                        >
                          <div className={"relative"}>
                            <img
                              src={user.profilePhoto || emptyAvatar}
                              alt={"avatar"}
                            />
                          </div>
                          <div style={{ marginLeft: "16px", flexGrow: 1 }}>
                            <div className={"name"}>{user.name}</div>
                            <div className={"username"}>@{user.username}</div>
                            <div className={"profession"}>
                              {user.profession}
                            </div>
                          </div>
                        </div>
                      </User>
                    );
                  })}
                </div>
              </Tab>
            </SWTabs>
          </div>
        ) : (
          <div className={"fangoutsContainer noscroll p016"}>
            {this.props.shows?.filter(filterShows).length > 0 && (
              <MostWanted>
                <div className={"heading"}>{lang.label_shows}</div>
                {this.props.shows.filter(filterShows).map((s) => (
                  <ShowItem key={s.id} show={s} />
                ))}
              </MostWanted>
            )}
            {/* 
            <MostWanted>
              <div className={'heading'}>{lang.most_popular}</div>
              <Flex gap={'10px'} className={'wrap space-around'}>
                {this.props.topPopular.map((u, i) => (
                  <PopularProfile key={i} user={u} />
                ))}
              </Flex>
            </MostWanted> */}

            {/* <MostWanted>
              <div className={'heading'}>{lang.most_active}</div>
              <Flex gap={'10px'} className={'wrap space-around'}>
                {this.props.topActive.map((u, i) => (
                  <PopularProfile key={i} user={u} />
                ))}
              </Flex>
            </MostWanted> */}

            {/* {fangoutsToShow.length > 0 && (
              <MostWanted>
                <div className={"heading"}>{lang.latest_fangouts}</div>
                {fangoutsToShow.map((f) => (
                  <FangoutComponent
                    f={f}
                    key={f.id}
                    expanded={this.state.expanded}
                    expandFangout={this.expandFangout}
                    goToProfile={this.goToProfile}
                    goToFangoutDetails={this.goToFangoutDetails}
                    withShare
                  />
                ))}
              </MostWanted>
            )} */}
          </div>
        )}

        {this.state.openFilterModal && (
          <Portal>
            <Popup
              position={"top"}
              title={"Filter Fangouts"}
              content={this.getFilterContent()}
              cancelAction={() => this.setState({ openFilterModal: false })}
              submitAction={this.onFilterSubmit}
              submitText={"Filter"}
            />
          </Portal>
        )}

        <Tabs active={0} lang={lang} />
      </HomeContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    fangouts: state.fangouts.fangouts,
    shows: state.fangouts.shows,
    lang: state.home.language,
    srcUsers: state.searchReducer.users,
    srcFangouts: state.searchReducer.fangouts,
    srcShows: state.searchReducer.shows,
    selectedTab: state.searchReducer.tab,
    searchTerm: state.searchReducer.searchTerm,
    topPopular: state.home.topPopular,
    topActive: state.home.topActive,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFangouts: () => setFangouts(dispatch),
    setShows: () => setShows(dispatch),
    searchUsers: (term) => searchUsers(dispatch, term),
    searchFangouts: (data, operatorOR) =>
      searchFangouts(dispatch, data, operatorOR),
    searchShows: (data, operatorOR) => searchShows(dispatch, data, operatorOR),
    clearSearchResults: () => clearSearchResults(dispatch),
    setTab: (tab) => setTab(dispatch, tab),
    setSearchTerm: (term) => setSearchTerm(dispatch, term),
    getTopActive: (count) => getTopActive(dispatch, count),
    getTopPopular: (count) => getTopPopular(dispatch, count),
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(Fangouts)
);

const HomeContainer = styled.div`
  overflow: hidden;

  .fangoutsContainer {
    padding-top: 16px;
    overflow: scroll;
    height: 100%;
    padding-bottom: 200px;
  }

  .heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;

const Header = styled.div`
  padding: 12px 16px 0 16px;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;

  .search {
    height: 0;
    position: relative;
    transition: all 0.1s ease-out;

    &.visible {
      height: 48px;
      // margin-bottom: 12px;
    }
  }

  .toggleSearch {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  i {
    &.fa-search {
      font-size: 20px;
    }

    &.icon-filter {
      font-size: 20px;
      display: flex;
      align-items: center;
    }

    &.icon-remove.reset-search {
      color: ${({ theme }) => theme.gray};
      position: absolute;
      right: 50px;
      top: 14px;
      font-size: 14px;

      &:active {
        opacity: 0.8;
      }
    }
  }
`;

const MainHeadingLine = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding-right: 6px;

  .icon-logo {
    font-size: 120px;
    margin-top: -42px;
  }
`;

const User = styled.div`
  &:nth-child(1) {
    padding-top: 10px;
  }

  margin-bottom: 12px;

  .userDetails,
  .caption {
    padding: 0 16px;
  }

  .userDetails {
    font-size: 16px;
    display: flex;

    img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
    }

    .name {
      font-size: 20px;
      font-weight: 500;
    }

    .username {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.gray};
    }
  }
`;

const ExtendedContent = styled(Content)`
  width: 100%;
  overflow: visible;
  min-height: 200px;

  #address {
    width: 100%;

    > div {
      background: ${({ theme }) => theme.background};
    }
  }
`;
const MostWanted = styled.div`
  padding: 5px 0;
  margin-bottom: 16px;
  border-radius: 16px;

  .heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
`;
