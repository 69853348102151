import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import postsReducer from './pages/posts/postsReducer';
import { tabsReducer } from './components/tabs/tabsReducer';
import { profileReducer } from './pages/profile/profileActions';
import fangoutsReducer from './pages/fangouts/fangoutsReducer';
import homeReducer from './pages/homeReducer';
import { requestsReducer } from './pages/requests/requestsReducer';
import { searchReducer } from './pages/requestFangout/search/searchReducer';
import { myFangoutsReducer } from './pages/myFangouts/myFangoutsReducer';

const middlewares = [];

const showLogs = true;

if (showLogs) {
  middlewares.push(
    createLogger({
      collapsed: true,
      // predicate: (getState, action) => action.type !== 'UPDATE_BBOX'
    })
  );
}

const reducer = combineReducers({
  home: homeReducer,
  posts: postsReducer,
  tabs: tabsReducer,
  profile: profileReducer,
  fangouts: fangoutsReducer,
  requests: requestsReducer,
  searchReducer,
  myFangoutsReducer,
});

export function getStore(preloadedState) {
  return createStore(reducer, preloadedState, applyMiddleware(...middlewares));
}
