import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import * as constants from '../../constants';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    // const searchParams = new URLSearchParams(this.props.location.search);
    this.session = localStorage.getItem(constants.SESSION_KEY);
  }

  render() {
    const { component: Component, ...rest } = this.props;

    let accessToken = null;
    if (this.session) {
      accessToken = JSON.parse(this.session).accessToken;
    }
    if (accessToken) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{ pathname: '/welcome', state: { from: props.location } }}
            />
          )}
        />
      );
    }
  }
}

export default withRouter(PrivateRoute);
