import React from 'react';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import { Switch, Route, withRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styling/theme';
import { GlobalStyles } from './styling/globalStyling';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/Login/ForgotPassword';
import axios from 'axios';
import { extractErrorMessage } from './tools';
import authService from './pages/Login/authService.js';
import Page404 from './pages/404/404';
import Welcome from './pages/welcome/welcome';
import Register from './pages/Login/Register';
import Verify from './pages/Login/verify';
import Profile from './pages/profile/profile';
import PrivateRoute from './components/privateRoute/privateRoute';
import PersonalDetails from './pages/personalDetails/personalDetails';
import { getSubscriptions, getUserData } from './pages/profile/profileActions';
import Notifications from './pages/notifications/NotificationsComponent';
import Toast, { T } from 'react-toast-mobile';
import { Toaster } from 'react-hot-toast';
import CreateFangout from './pages/createFangout/createFangout';
import Fangouts from './pages/fangouts/fangouts';
import PublicProfile from './pages/publicProfile/publicProfile';
import FangoutDetails from './pages/fangoutDetails/fangoutDetails';
import { setFangouts } from './pages/fangouts/fangoutsActions';
import Referrals from './pages/referrals/referrals';
import RequestFangout from './pages/requestFangout/requestFangout';
import Requests from './pages/requests/requests';
import Settings from './pages/settings/settings';
import 'react-toast-mobile/lib/react-toast-mobile.css';
import 'react-table-v6/react-table.css';
import { setTab } from './pages/requestFangout/search/searchReducer';
import StripeContainer from './components/stripe/stripeContainer';
import { PTR } from './components/pullToRefresh/pullToRefresh';
import MyFangouts from './pages/myFangouts/MyFangouts';
import notificationsService from './notificationsService';
import AdminHook from './pages/admin/adminHookComponent';
import ResetPassword from './pages/Login/ResetPassword';
import Disputes from './pages/admin/components/disputes/disputes';
import Subscriptions from './pages/subscriptions/subscriptions';
import Availability from './pages/availability/availability';
import MyAgent from './pages/myAgent/myAgent';
import Premium from './pages/premium/Premium';
import StripePremiumPayment from './pages/payment/PremiumPayment';
import CreateShow from './pages/createShow/createShow';
import MyShows from './pages/myShows/myShows';
import MyTickets from './pages/myTickets/myTickets';
import Show from './pages/show/Show';
import TicketInvalidator from './pages/ticketInvalidator/TicketInvalidator';
import ShowDashboard from './pages/showDashboard/ShowDashboard';
import TicketPage from './pages/ticket/Ticket';
import StripeTicketPayment from './pages/payment/TicketPayment';
import { loadGapiInsideDOM, gapi, loadAuth2WithProps } from 'gapi-script';
import GratisGenerator from './pages/gratisGenerator';
import SoldTicketReporter from './pages/ticketInvalidator/SoldTicketsReporter';

const clientId = process.env.REACT_APP_G_CLIENT_ID;

let isFetchingToken = false;
let tokenSubscribers = [];

function subscribeTokenRefresh(cb) {
  tokenSubscribers.push(cb);
}

function onTokenRefreshed(errRefreshing, token) {
  tokenSubscribers.map((cb) => cb(errRefreshing, token));
}

export function forceLogout() {
  const accessToken = authService.getAccessToken();
  notificationsService.unsubscribeUser(accessToken);
  isFetchingToken = false;
  // localStorage.clear();
  authService.invalidateSession();
  gapi.auth2.getAuthInstance().signOut();
  window.location.hash = '/';
}

axios.interceptors.request.use(
  (reqConfig) => {
    const accessToken = authService.getAccessToken();
    if (accessToken) {
      reqConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return reqConfig;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(undefined, (err) => {
  if (extractErrorMessage(err) === 'refreshTokenNotFoundOrExpired')
    return forceLogout();
  // if (err.message === 'Network Error' || err.response.status === 400 || err.response.status === 404 ||
  // 	err.response.status === 500 || err.response.status === 422) return Promise.reject(err)
  if (
    err.message === 'Network Error' ||
    (err.response && err.response.status !== 403)
  )
    return Promise.reject(err);

  // Save refreshToken
  const refreshToken = authService.getRefreshToken();

  if (!isFetchingToken && refreshToken) {
    isFetchingToken = true;
    if (!refreshToken) return forceLogout();

    authService
      .refreshAccessToken(refreshToken)
      .then((res) => {
        const session = res.data;
        isFetchingToken = false;
        onTokenRefreshed(null, session.accessToken);
        tokenSubscribers = [];
      })
      .catch((e) => {
        console.log('[error refresh token]', e);
        tokenSubscribers = [];
      });
  }

  const initTokenSubscriber = new Promise((resolve, reject) => {
    subscribeTokenRefresh((errRefreshing, newToken) => {
      if (errRefreshing) return reject(errRefreshing);

      err.config.headers.Authorization = `Bearer ${newToken}`;
      return resolve(axios(err.config));
    });
  });
  return initTokenSubscriber;
});

class App extends React.Component {
  state = {
    icon: false,
  };

  gapiInit = async () => {
    await loadGapiInsideDOM();
    await loadAuth2WithProps(gapi, { clientId });
  };

  componentDidMount() {
    this.gapiInit();
    // this.initializePushMessages()
    if (!this.props.userData.username) {
      this.props.getUserData();
    }
    if (this.props.fangouts.length === 0) {
      this.props.setFangouts();
    }
    // this.props.setSearchTab(this.props.lang.tabs_fangouts)
    this.props.setSearchTab(1);
    this.props.getSubscriptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.update) {
      T.confirm({
        title: 'Update',
        message: 'Reload app to get the update',
        option: [
          {
            text: 'Reload',
            fn: () => {
              const registrationWaiting = nextProps.update.waiting;
              if (registrationWaiting) {
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
                registrationWaiting.addEventListener('statechange', (e) => {
                  if (e.target.state === 'activated') {
                    window.location.reload();
                  }
                });
              }
            },
          },
          {
            text: 'Later',
            fn: () => {},
          },
        ],
      });
    }
  }

  render() {
    const theme = this.props.lightTheme ? lightTheme : darkTheme;
    return (
      <PTR isPullable={this.props.isPullable}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Toast />
          <Toaster
            toastOptions={{
              // Define default options
              duration: 4000,
              style: {
                background: theme?.secondBackground,
                color: theme?.text,
              },
            }}
          />
          <div className='App'>
            <Switch>
              <Route exact path={'/'} component={Fangouts} />
              <Route exact path={'/home'} component={Fangouts} />
              {/*<Route exact path={'/posts'} component={Posts}/>*/}
              <Route exact path={'/public/:id'} component={PublicProfile} />
              <Route exact path={'/fangout/:id'} component={FangoutDetails} />
              <Route exact path={'/ticket/:id'} component={TicketPage} />

              <PrivateRoute
                path={'/requests/:selectedTab?/:fangoutId?'}
                component={Requests}
              />
              <PrivateRoute exact path={'/profile'} component={Profile} />
              <PrivateRoute
                exact
                path={'/notifications'}
                component={Notifications}
              />
              <PrivateRoute
                exact
                path={'/personalDetails'}
                component={PersonalDetails}
              />
              {/*<PrivateRoute exact path={'/newpost'} component={CreatePost}/>*/}
              {/* <PrivateRoute
                exact
                path={"/newfangout"}
                component={CreateFangout}
              /> */}
              <PrivateRoute
                exact
                path={'/editfangout/:id'}
                component={CreateFangout}
              />
              <PrivateRoute
                exact
                path={'/requestfangout/:userId'}
                component={RequestFangout}
              />
              <PrivateRoute exact path={'/newshow'} component={CreateShow} />
              <PrivateRoute exact path={'/myshows'} component={MyShows} />
              <PrivateRoute
                exact
                path={'/myshows/:showId'}
                component={ShowDashboard}
              />
              <PrivateRoute
                exact
                path={'/gratisGenerator/:showId'}
                component={GratisGenerator}
              />
              <PrivateRoute
                exact
                path={'/editshow/:showId'}
                component={CreateShow}
              />
              <PrivateRoute exact path={'/mytickets'} component={MyTickets} />
              <Route exact path={'/show/:showId'} component={Show} />
              <Route
                exact
                path={'/invalidator/:showCode'}
                component={TicketInvalidator}
              />
              <Route
                exact
                path={'/reporter/:showCode'}
                component={SoldTicketReporter}
              />
              <PrivateRoute exact path={'/referrals'} component={Referrals} />
              <PrivateRoute exact path={'/settings'} component={Settings} />
              <PrivateRoute exact path={'/admin'} component={AdminHook} />
              <PrivateRoute
                exact
                path={'/admin/disputes'}
                component={Disputes}
              />
              <PrivateRoute exact path={'/myfangouts'} component={MyFangouts} />
              <PrivateRoute
                exact
                path={'/subscriptions'}
                component={Subscriptions}
              />
              <PrivateRoute
                exact
                path={'/availability'}
                component={Availability}
              />
              <PrivateRoute exact path={'/myagent'} component={MyAgent} />
              <PrivateRoute exact path={'/premium'} component={Premium} />
              <Route exact path={'/welcome'} component={Welcome} />
              <Route
                exact
                path={'/resetpassword/:token'}
                component={ResetPassword}
              />
              <Route exact path={'/login'} component={Login} />
              <Route path={'/register/:ref?'} component={Register} />
              <Route
                exact
                path={'/forgotPassword'}
                component={ForgotPassword}
              />
              <Route exact path={'/verify'} component={Verify} />
              <PrivateRoute exact path={'/pay'} component={StripeContainer} />
              <PrivateRoute
                exact
                path={'/premiumpay'}
                component={StripePremiumPayment}
              />
              <Route
                exact
                path={'/ticketspay'}
                component={StripeTicketPayment}
              />
              <Route component={Page404} />
            </Switch>
          </div>
        </ThemeProvider>
      </PTR>
    );
  }
}

function mapStateToProps(state) {
  return {
    test: state.posts.test,
    update: state.posts.update,
    offline: state.posts.offline,
    userData: state.profile.userData,
    fangouts: state.fangouts.fangouts,
    lightTheme: state.home.lightTheme,
    lang: state.home.language,
    isPullable: state.home.isPullable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: () => getUserData(dispatch),
    setFangouts: () => setFangouts(dispatch),
    setSearchTab: (tab) => setTab(dispatch, tab),
    getSubscriptions: () => getSubscriptions(dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
