import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { InputField, InputFieldNative } from '../../styling/globalStyling';
import * as PropTypes from 'prop-types';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  margin-top: 32px;
  label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
    span {
      font-size: 12px;
      font-weight: 500;
      text-transform: initial;
    }
  }
  i {
    font-size: 22px;
    color: #cacaca;
  }
  &.borderPrimary {
    border: 1px solid ${({ theme }) => theme.red};
  }
`;

export const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: ${(props) => (props.label ? '34px' : '10px')};
`;

export const Img = styled.img`
  position: absolute;
  right: 15px;
  // top: ${(props) => (props.label ? '34px' : '10px')};
  top: 34px;
  width: 32px;
`;

export const Description = styled.div`
  font-size: 12px;
  position: absolute;
  bottom: -18px;
`;

class Input extends React.PureComponent {
  render() {
    const {
      label,
      description,
      wrapperstyle,
      inputstyle,
      descriptionPositionUp,
      iconClickHandler,
      horizontal,
      reference,
      className,
      hasLabelSpace,
      ...propsToPass
    } = this.props;
    const icon =
      this.props.icon && this.props.icon.indexOf('data:image') !== -1 ? (
        <Img src={this.props.icon} alt={'icon'} />
      ) : (
        <Icon label={this.props.label}>
          <i className={this.props.icon + ' link'} onClick={iconClickHandler} />
        </Icon>
      );

    return (
      <InputWrapper
        className={className}
        horizontal={horizontal}
        style={wrapperstyle}
      >
        {label ? (
          <label>
            {label} {descriptionPositionUp && <span>{description}</span>}
          </label>
        ) : hasLabelSpace ? (
          <label>&nbsp;</label>
        ) : null}
        <InputField
          style={{
            paddingRight: this.props.icon ? '50px' : '16px',
            ...inputstyle,
          }}
          autoComplete='off'
          {...propsToPass}
        />
        {this.props.icon && icon}
        {description && !descriptionPositionUp && (
          <Description>{description}</Description>
        )}
      </InputWrapper>
    );
  }
}

Input.propTypes = {
  icon: PropTypes.string,
  descriptionPositionUp: PropTypes.bool,
  horizontal: PropTypes.bool,
  description: PropTypes.any,
  inputstyle: PropTypes.object,
  wrapperstyle: PropTypes.object,
  reference: PropTypes.object,
};

export default Input;
