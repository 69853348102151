import axios from 'axios'
import config from "../../config";


class stripeService {
	makePayment(options) {
		const {paymentMethodId, fangoutId} = options
		const url = config.baseApi + '/v1/payments'
		return axios.post(url, {paymentMethodId, fangoutId})
	}
	makePremiumPayment(options) {
		const {paymentMethodId, durationMonths} = options
		const url = config.baseApi + '/v1/payments/premium'
		return axios.post(url, {paymentMethodId, durationMonths})
	}
	makeTicketPayment(options) {
		const url = config.baseApi + '/v1/payments/tickets'
		return axios.post(url, options)
	}
	makeGuestTicketPayment(options) {
		const url = config.baseApi + '/v1/public/buy_tickets/unauthenticated'
		return axios.post(url, options)
	}
}

export default new stripeService()